/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li",
    h4: "h4",
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Stack"), "\n", React.createElement(_components.h3, null, "Definition"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "linear data structure"), "\n", React.createElement(_components.li, null, "Last in first out (LIFO) or first in last out (first in last out)"), "\n", React.createElement(_components.li, null, "operations on only one end"), "\n", React.createElement(_components.li, null, "push add element to stack"), "\n", React.createElement(_components.li, null, "pop remove element from stack"), "\n"), "\n", React.createElement(_components.h4, null, "Extended Definition"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "peek return the last value added to the stack without changing the stack"), "\n", React.createElement(_components.li, null, "size return the size of the stack"), "\n"), "\n", React.createElement(_components.h3, null, "Stack Implementation in JavaScript"), "\n", React.createElement(_components.h4, null, "Array as a Stack"), "\n", React.createElement(_components.p, null, "There are many ways the definition of a stack can be implemented in JavaScript we will start with a sufficient implementation the build in array method."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "let stack = []\n\nstack.push(\"a\")\nstack.push(\"b\")\nstack.push(\"c\")\nconsole.log(stack) // ['a', 'b', 'c']\n\nstack.pop() // 'c'\nconsole.log(stack) // ['a', 'b']\n")), "\n", React.createElement(_components.p, null, "By constraining how we used the array object we can leverage it as a stack. This is useful when using a stack and it's properties is ideal for solving a challenge."), "\n", React.createElement(_components.p, null, "for example reverse a string;"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "function reverse(str) {\n  let stack = []\n  for (let i = 0; i < str.length; i++) {\n    stack.push(str[i])\n  }\n  let reverseStr = \"\"\n  for (let j = 0; j < str.length; j++) {\n    reverseStr += stack.pop()\n  }\n  return reverseStr\n}\n\nreverse(\"hello world\") // dlrow olleh\n")), "\n", React.createElement(_components.h3, null, "Class Implementation Stack One"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "class Stack {\n  constructor() {}\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
